import React from 'react';
import Header from "../Components/header";
import Footer from "../Components/footer";
import FourZeroContent from "../Components/FourZeroContent";
export default function FourZeroFour() {
    return (
        <>
          <Header/>
          <FourZeroContent/>
          <Footer/>
        </>
    )
}
