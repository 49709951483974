import React, { useState, useEffect } from "react";
import Api from "./api";
const Fetch = () => {
  const [dataF, setDataF] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetch("./api/test2")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        // console.log("before setting data to dataF", data);
        setDataF(data);
      })
      .catch((error) => {
        console.error("error fetching", error);
        setError(error);
      })
      .finally(() => {
        // console.log("finally");
        // setLoading(false);
      });
  }, []);

  return (
    <div className="container-big">
      {dataF &&
        dataF.map((el, idx) => {
          return (
            <div key={idx}>
              <Api id={el.id} i={idx} quote={el.quote} />
            </div>
          );
        })}
    </div>
  );
};
export default Fetch;
