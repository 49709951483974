import React from "react";
import {
  FaEnvelope,
  FaPhone,
  FaSkype,
  FaTwitter,
  FaLinkedinIn,
  FaGithub,
} from "react-icons/fa";
import SectionTitle from "./section_title";

export default function Contact() {
  return (
    <div className="contact-area section-ptb" id="contact">
      <div className="container">
        <SectionTitle
          title="CONTACT"
          subTitle="Connect With Me"
          // desc=" description ..."
        />
      </div>
      <div className="container mt-45">
        <div className="row md-justify-content-center">
          <div className="col-lg-4 col-md-6 mb-30">
            <div className="contact-item">
              <div className="ct-item-icon float-left ml-30 mr-20">
                <i>
                  <FaEnvelope />
                </i>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn-animation"
                href="mailto:alev7ina@gmail.com"
              >
                alev7ina@gmail.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <div className="contact-item">
              <div className="ct-item-icon float-left ml-30 mr-20">
                <i>
                  <FaPhone />
                </i>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn-animation"
                href="tel:4697207399"
              >
                +1 (469) 720 7399
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <div className="contact-item">
              <div className="ct-item-icon float-left ml-30 mr-20">
                <i>
                  <FaSkype />
                </i>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn-animation"
                href="skype:<alevtina7777><action>"
              >
                skype me
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-30 mb-lg-0">
            <div className="contact-item">
              <div className="ct-item-icon float-left ml-30 mr-20">
                <i>
                  <FaTwitter />
                </i>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn-animation"
                href="https://twitter.com/alev7ina"
              >
                @alev7ina
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-30 mb-lg-0">
            <div className="contact-item">
              <div className="ct-item-icon float-left ml-30 mr-20">
                {/* <i><FaDribbble /></i> */}
                <i>
                  <FaLinkedinIn />
                </i>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn-animation"
                href="https://www.linkedin.com/in/alevtinabrown/"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-30 mb-lg-0">
            <div className="contact-item">
              <div className="ct-item-icon float-left ml-30 mr-20">
                <i>
                  <FaGithub />
                </i>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="btn-animation"
                href="https://github.com/alev7ina"
              >
                alev7ina
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
