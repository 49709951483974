import Star from "../assets/svg/star";
import React, { useState, useEffect } from "react";

function App() {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl = "./api/quotes";

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClick = () => {
    if (data.length > 0) {
      const nextIndex = (currentIndex + 1) % data.length;
      setCurrentIndex(nextIndex);
    }
  };

  const currentItem = data[currentIndex];
  // const formatedItem= JSON.stringify(currentItem.quote, null, 2)
  return (
    <div>
      <div className="apidata">
        {isLoading && <p>Loading data...</p>}
        {/* {error && <p>Error: {error}</p>} */}
        {error && <p className="pc quote">Quotes went to sleep 🌙</p>}
        {data.length > 0 && (
          <>
            <p className="quote" onClick={handleClick}>
              ⭐ "{currentItem.quote}
              <span className="pc" style={{ fontSize: 30 }}>
                .
              </span>
              "<p>{currentItem.author}</p>
            </p>
          </>
        )}
        {data.length === 0 && !isLoading && !error && <p>No data found.</p>}
      </div>
    </div>
  );
}

export default App;
